.product-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.product-card{
    width: 200px;
    flex: 1 1 200px;
    margin: 0px 20px 50px 20px;
    position: relative;
}

.product-image{
    width: 100%;
    height: 100%;
}

.product-details{
    width: 100%;
    max-height: 70px;
    background-color: rgba(0,0,0, 0.5);
    color: #f5f5f5;
    position: absolute;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    transition: 0.5s ease;
    overflow: hidden;
}

.product-title{
    font-family: "Fraunces", serif;
    font-size: 20px;
    font-weight: 600;
    font-style: italic;
    padding-top: 8px;
    margin-bottom: 5px;
}

.product-price{
    font-family: "Mako", sans-serif;
}

.product-card:hover .product-details{
    height: 100%;
}

@media (max-width: 821px) {
    .product-title {
        font-size: 14px;
        padding-top: 10px;
    }
    .product-price{
        font-size: 14px;
    }
}