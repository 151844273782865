.product-main-con{
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.product-left-pad, .product-right-pad{
    flex: 0 0 20vw;
    min-width: 20vw;
}

.product-detail{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.product-gallery {
    flex: 1 1 350px;
    max-width: 350px;
    /* min-height: 400px; */
    display: flex;
}

.product-gallery-preview{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-right: 5px;
    bottom: 0;
    align-self: flex-end;
}

.preview-image{
    width: 60px;
    /* height: 60px; */
    object-fit: cover;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.preview-image.active{
    opacity: 1;
    filter: grayscale(50%);
}

.product-gallery-selected-image{
    position: relative;
    width: 100%;
}

.gallery-main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 30px;
    transition: background 0.3s;
}

.nav-button.prev {
    left: 10px;
}

.nav-button.next {
    right: 10px;
}

.nav-button:hover {
    background: rgba(0, 0, 0, 0.7);
}

.product-detail-texts{
    flex: 1 1 200px;
    /* padding-top: 5vh; */
    max-width: 400px;
}

.product-detail-title{
    font-family: "Fraunces", serif;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 20px;
}

.product-detail-desc, .product-detail-info, .product-detail-sizing, .product-detail-styling{
    font-family: "Mako", sans-serif;
    font-size: 18px;
    text-align: left;
    padding: 0px 30px;
    margin-bottom: 30px;
}

.product-detail-sizing, .product-detail-styling{
    font-size: 14px;
}

.product-detail-price{
    font-family: "Fraunces", serif;
    font-size: 24px;
    font-weight: 800;
    text-align:left;
    padding: 0px 30px;
}

@media (max-width: 821px) {
    .product-detail-texts {
        padding-top: 30px; /* Removes top margin when flex wraps */
    }
    .product-detail-title{
        font-size: 30px;
    }
    .product-detail-desc, .product-detail-info, .product-detail-sizing, .product-detail-styling{
        padding: 0px 28px;
    }
    .nav-button{
        font-size: 25px;
    }
    .nav-button.prev {
        left: 5px;
    }
    
    .nav-button.next {
        right: 5px;
    }
}

@media (min-width:2000px){
    .product-detail{
        justify-content: center;
    }
    .product-detail-texts{
        margin: 0px 40px;
    }
}