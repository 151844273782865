.launch-picture{
    width: 70vw;
}

@media (max-width: 821px){
    .launch-picture{
        width: 100vw;
    }
}

.home-main-con{
    display: flex;
    margin-bottom: 30px;
}

.home-left-pad, .home-right-pad{
    width: 12%;
}

.home-center-con{
    flex: 1;
    background-color: rgba(245, 245, 245, 1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.home-picture{
    width: 300px;
    aspect-ratio: 1 / 1;
    margin: 8% 2%;
    object-fit: cover;
    object-position: 50% 100%;
}

.home-text{
    position: relative;
    width: 45%;
    text-align: left;
    font-family:"Fraunces", serif;
    margin: 0px 40px;
}

.home-text-1{
    font-size: 55px;
    font-weight: 500;
    margin-bottom: 30px;
}

.home-text-2, .home-text-3{
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 30px;
}

.home-text-4{
    font-size: 25px;
    font-family: "Birthstone", cursive;
    position: absolute;
    bottom: -10px;
    right: 0;
    transform: rotate(-20deg);
}

@media (max-width: 821px) {
    .home-picture{
        margin: 20px 0px;
    }
    .home-text{
        width: 300px;
    }
    .home-text-1{
        font-size: 30px;
    }
    .home-text-2, .home-text-3{
        font-size: 20px;
        font-weight: 300;
        margin-bottom: 30px;
    }
    .home-text-3{
        margin-bottom: 80px;
    }
    .home-text-4{
        bottom: 30px;
        right: 40px;
    }
}