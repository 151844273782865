.header{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
}

.header-btm-line{
    width: 300px;
    border-bottom: 2px solid gray;
    margin-bottom: 15px;
}

.header-logo{
    width: 100px;
}

.header-navigation{
    display: flex;
}

.header-nav-option{
    margin: 0px 20px;
    text-decoration: none;
    color: black;
    font-family: "Mako", sans-serif;
}

/* Dropdown Container */
.header-navigation .header-nav-option {
    position: relative;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 22px;
    left: -10px;
    background-color: white;
    border: 1px solid gray;
    border-radius: 4px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
    z-index: 10;
}

/* Dropdown Items */
.dropdown-item {
    display: block;
    padding: 8px 10px;
    text-decoration: none;
    color: black;
    font-family: "Mako", sans-serif;
}

.dropdown-item:hover {
    background-color: rgb(235, 235, 235);
}

/* Ensure the main nav options remain inline */
.header-navigation {
    display: flex;
    align-items: center;
}
