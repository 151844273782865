.catalogue-main-con{
    display: flex;
    justify-content: center;
}

.catalogue-left-pad, .catalogue-right-pad{
    flex: 0 0 20vw;
}

.catalogue-middle-pad{
    flex: 1;
}